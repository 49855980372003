import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const FlorilePage = () => (
  <Layout>
    <SEO title="Cum alegem florile" />
    <div className="drawer article-content">
      <h1 className="article-title">Cum alegem florile?</h1>

      <p>
        Fiecare detaliu contribuie la conturarea unei povești frumoase, iar
        aranjamentelor trebuie să le acordam o atentie deosebita. Florile ar
        trebui să se potrivească cu povestea, cu decorul sălii, cu anotimpul și
        cel mai important, cu gusturile tale 😊.
      </p>

      <h3 className="paragh-title">Alegerea florilor în funcție de anotimp</h3>
      <p>
        Este clar că pe lângă aspectul de naturalețe, să ai flori specifice
        sezonului înseamnă și un cost mai mic decât în extra sezon.
      </p>

      <p>
        <b>Primăvara</b> poți alege dintr-o mare varietate de flori: lalele,
        zambile, liliac, margaritar, bujori. Și poti alege și culori care
        exprimă gingășia anotimpului: roz, bleu, lila sau culori pastel.
      </p>
      <img src="https://images.unsplash.com/photo-1527678826328-0597f0250279?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=668&q=80" />
      <p>
        <b>Vara</b> poți opta pentru culori mai vii și poți alege trandafirii,
        floarea-soarelui, iris, cala, frezie, bineînteles dacă decorul sau
        tematica iți permit. In caz contar, te poți baza oricând și pe florile
        albe.
      </p>
      <img src="https://images.unsplash.com/photo-1519225421980-715cb0215aed?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80" />
      <p>
        <b>Toamna</b>, buchetele pot fi in nuanțe de burgundy, rosu, portocaliu,
        galben, la care poți adăuga si ceva “verdeață”, de exemplu eucalipt.
        Poți folosi crizanteme, floarea-soarelui sau trandafiri.
      </p>
      <p>
        <b>Iarna</b> poți alege flori albe (poți alege chiar flori de bumbac) și
        câteva frunze verzi, iți poti crea decoruri cu plante suculente și poți
        apela și la cateva conuri de brad.
      </p>
      <img src="https://images.unsplash.com/photo-1496881583630-b3c77fcd76e5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=668&q=80" />

      <h3 className="paragh-title">
        Alegerea florilor în funcție de decorul sălii și tematica nunții
      </h3>
      <p>
        {" "}
        Nunta cu tematică romantică fie va avea flori mai delicate (floarea
        miresei, bujori, margaritar) fie va avea culori mai deschise sau nuanțe
        pastel, dacă decorul restaurantului permite. Dacă sala are covor și
        scaune cu tapi erie roșii, iar restul decorațiunilor sunt aurii poate nu
        ar trebui să te orientezi pe flori roz. Dacă nunta ta este elegantă, nu
        te baza pe florile de camp sau pe crizanteme. La o nunta rustică poți
        adauga sfoară pe vazele în care sunt florile.
      </p>
      <p>
        Pe lângă flori poți adăuga și alte elemente decorative: conuri de brad
        iarna, mărgele la o nuntă cu tematică Gatsby Party, scoici și nisip la
        tematica marina, suveniruri din vacanțe la tema călătorii, cărti sau
        baloane. Încearcă doar să nu combini prea multe elemente diferite sau
        prea multe culori.
      </p>

      <h3 className="paragh-title">Florile artificiale?</h3>
      <p>
        Tot mai multe persoane aleg varianta de flori artificiale. Poate sunt o
        varianta de luat în calcul la o nunta ce are loc iarna sau daca faci o
        nunta în vârful muntelui și sunt mai ușor de transportat sau poate dacă
        nu gasești floarea ta preferată. Cu siguranță, aranjamentele de pe masă
        nu sunt cel mai important aspect al unei nunți, însă acordând importanța
        fiecarui element, cu siguranță vei avea o nuntă excepțională.
      </p>

      <div className="nav-section">
        <Link to="/tematica">◀ Tematica nunții</Link>
        <Link to="/muzica">▶ Muzica la nuntă</Link>
      </div>
    </div>
  </Layout>
)

export default FlorilePage
